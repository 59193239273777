import {
  languages as _languages,
  countries,
  genders,
  departments,
  seniorities,
} from "@efcloud/el-b2b-constants";

// GAP-11437 One Traditional Chinese in language list
const languages = _languages.filter((language) => language.value !== "zh-HK");

export { languages, countries, genders, departments, seniorities };
