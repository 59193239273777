// checkCriticalFeatureSupport needs to be imported first so that all features get
// checked before the polyfills get applied
import { checkCriticalFeatureSupport } from '../../../common/Elm/src/JsUtils/featureCheck.js';
import './polyfills';
import 'regenerator-runtime/runtime.js';
// eslint-disable-next-line
import { addErrorHandler } from 'single-spa';
import { bootstrap } from './bootstrap';
import { initialI18n } from './translations';
import { logError } from './utils/logging';
import { error, logoutClass } from './views/components/error';
import { AuthenticationError } from './utils/auth-error';

import '../../../common/style/assets/img/default-image.png';
import '../../../common/style/scss/styles.scss';
import './utils/pageViewTracking';
import { configureRoutes } from './routing';
import { PageMovedError } from './utils/page-moved-error';

const checkSupport = (): Promise<undefined|Error> => new Promise((resolve, reject) => {
    if (checkCriticalFeatureSupport()) {
        resolve();
    }
    reject(new Error('Support for Cookies and/or local storage not enabled'));
});

const initDebugBlurbs = (): void => {
    const query = window.location.search;
    const urlParams = new URLSearchParams(query);
    const debugBlurbs = urlParams.get('debugBlurbs');

    if (debugBlurbs === 'true') {
        localStorage.setItem('debugBlurbs', 'true');
    }
    if (debugBlurbs === 'false') {
        localStorage.removeItem('i18nCache');
        localStorage.setItem('debugBlurbs', 'false');
    }
};

const showError = (): void => {
    document.body.innerHTML = error({
        title: 'Sorry!',
        infoText: 'An error has occurred. Please try again later.',
        buttonText: 'Log out',
        buttonType: 'Logout',
    });

    const $logout = document.querySelector(`.${logoutClass}`);
    if ($logout) {
        $logout.addEventListener('click', () => {
            document.dispatchEvent(new CustomEvent('platform-auth.logout'));
        });
    }
};

checkSupport()
    .then(initDebugBlurbs)
    .then(initialI18n)
    .then(bootstrap)
    .then(configureRoutes)
    .catch((err) => {
        // let's not log any auth errors to the server because it is a normal
        // occurence and just means we need to go off to the login controller
        // same fo PageMovedError where we need to redirect to another page
        if (!(err instanceof AuthenticationError) && !(err instanceof PageMovedError)) {
            logError(err);
            showError();
        }
    });


addErrorHandler(logError);
window.addEventListener('error', logError);
window.addEventListener('unhandledrejection', logError);

// this is horrible but don't blame me - blame cypress
if (window.Cypress) {
    const [html] = document.getElementsByTagName('html');
    if (html) {
        html.style.scrollBehavior = 'auto';
    }
}
