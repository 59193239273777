import { AuthenticationError } from './auth-error';

const log = <T>(isDebug: boolean, msg: T): T => {
    if (isDebug) {
        global.console.log('DEBUG: ', msg);
    } else {
        global.console.error(msg);
    }
    return msg;
};

const debug = <T>(msg: T): T => log(true, msg);
const error = <T>(msg: T): T => log(false, msg);

function extractMessageFromError(err: unknown): string {
    if (window.PromiseRejectionEvent && err instanceof PromiseRejectionEvent) {
        return err.reason.message;
    }
    if (err instanceof Error) {
        return err.message;
    }
    return String(err);
}

function logError(msg: unknown): void {
    if (msg instanceof AuthenticationError) {
        // if any downstream services return auth errors then we will already have logged from the BFF if necessary
        return;
    }
    const msgStr = extractMessageFromError(msg);
    error(msgStr);
}
export { debug, error, logError };
